import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import edjsParser from "editorjs-parser"

import "./blog-post.css"

const BlogPostTemplate = ({ data, location }) => {
  const article = data.directus.articles[0]
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const customParsers = {
    image: function (blockData, config) {
      if (blockData.file.url.startsWith("/assets/")) {
        let assetUrl =
          data.site.siteMetadata.remoteAssetsHost +
          blockData.file.url.replace("/assets", "")
        return `<img src="${assetUrl}" alt="${blockData.caption}" >`
      }
      return `<img src="${blockData.file.url}" alt="${blockData.caption}" >`
    },
    quote: function (blockData, config) {
      return `
      <div class="max-w-4xl my-4 p-4 text-gray-800 bg-white rounded-lg shadow">
        <div class="mb-2">
          <div class="h-3 text-3xl text-left text-gray-600">“</div>
          <p class="px-4 py-4 text-2xl text-center text-gray-600">
            ${blockData.text}
          </p>
          <p class="px-4 text-sm text-right italic text-gray-600">
            ${blockData.caption}
          </p>
          <div class="h-3 text-3xl text-right text-gray-600">”</div>
        </div>
      </div>
      `
    },
    paragraph: function (data, config) {
      if (/https:\/\/www.youtube.com\/watch\?v=([\w\-]+)/.test(data.text.trim())) {
        return `
          <div class="relative" style="padding-top: 56.25%">
            <iframe frameborder="0" allowfullscreen class="absolute inset-0 w-full h-full" src="${data.text.replace('watch?v=', 'embed/')}"></iframe>
          </div>
          `
      }
      return `<p class="${config.paragraph.pClass}"> ${data.text} </p>`
    },
  }
  const parser = new edjsParser(undefined, customParsers)
  const content = parser.parse(article.content)

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={article.title} description={article.title} />
      <div className="md:container md:mx-auto mt-6">
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <div className="max-w-screen-xl mx-auto p-5 sm:p-6 md:p-10 relative">
            <div
              className="bg-cover h-40 md:h-96 w-auto text-center overflow-hidden"
              style={{
                backgroundImage: `url('${data.site.siteMetadata.remoteAssetsHost}/${article.image.id}?key=webp')`,
              }}
              title={article.title}
            ></div>
            <div className="max-w-2xl mx-auto">
              <div className="mt-3 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                <div className="">
                  <a
                    href={`/category/${article.category.slug}`}
                    className="text-xs text-indigo-600 uppercase font-medium hover:text-gray-900 transition duration-500 ease-in-out"
                  >
                    {article.category.title}
                  </a>
                  <header>
                    <h1
                      itemProp="headline"
                      className="text-gray-900 font-bold text-3xl mb-2"
                    >
                      {article.title}
                    </h1>
                  </header>
                  <section className="text-gray-700 text-xs my-2">
                    <a
                      href={`/author/${article.author.slug}`}
                      className="text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out"
                    >
                      {article.author.name}
                    </a>
                  </section>
                  <section
                    dangerouslySetInnerHTML={{ __html: content }}
                    itemProp="articleBody"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
        remoteAssetsHost
      }
    }
    directus {
      articles(filter: { id: { _eq: $id } }) {
        id
        content
        published_date_func {
          day
          year
          month
        }
        slug
        title
        image {
          id
        }
        author {
          name
          slug
        }
        category {
          title
          slug
        }
      }
    }
  }
`
